@mixin landing-page-background() {
  background-color: $ui-01;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: -$spacing-05;
    top: 0;
    right: -$spacing-05;
    bottom: 0;
    background: $ui-01;
    z-index: -1;
  }
}